import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IsEditMessageTypes } from '../../../types/channels';

import type { PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
  category: string | null;
  isSell: boolean;
  isBuy: boolean;
  isMandatoryApproval: boolean | null;
  isOpenChannelsFilterModal: boolean;
  searchStr: string;
  isEditMessage: IsEditMessageTypes;
}

const initialState: FilterState = {
  category: 'ALL',
  isBuy: false,
  isMandatoryApproval: null,
  isSell: false,
  isOpenChannelsFilterModal: false,
  searchStr: '',
  isEditMessage: 'ALL',
};

export const channelsFilterSlice = createSlice({
  name: 'channels-filter',
  initialState,
  reducers: {
    setIsSellChannelsFilter(state, action: PayloadAction<boolean>) {
      state.isSell = action.payload;
    },
    setIsBuyChannelsFilter(state, action: PayloadAction<boolean>) {
      state.isBuy = action.payload;
    },
    setSearchStrChannelsFilter(state, action: PayloadAction<string>) {
      state.searchStr = action.payload;
    },
    setIsMandatoryApprovalChannelsFilter(state, action: PayloadAction<boolean | null>) {
      state.isMandatoryApproval = action.payload;
    },
    setCategoryFilterChannelsFilter(state, action: PayloadAction<string | null>) {
      state.category = action.payload;
    },
    setIsOpenChannelsFilterModal(state, action: PayloadAction<boolean>) {
      state.isOpenChannelsFilterModal = action.payload;
    },
    setIsEditMessageChannelsFilterModal(state, action: PayloadAction<IsEditMessageTypes>) {
      state.isEditMessage = action.payload;
    },
  },
});

export const channelsFilterActions = channelsFilterSlice.actions;

export const selectChannelsFilter = (state: RootState) => state.channelsFilter;

export default channelsFilterSlice.reducer;
